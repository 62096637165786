import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"



const Background = styled.main`
  background : ${({ theme }) => theme.backgroundLight};
  height: 100vh;
  max-height: 100vh;
  position: relative;
`

const Text = styled.div`
  color: ${({ theme }) => theme.colorText};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-family: STHeiti;
  font-weight:bold;
  font-size: 2rem;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
      font-size: 5rem;
  }
`

const LinkHome = styled(Link)`
  color: ${({ theme }) => theme.colorText};
`

const Paragraph = styled.p`
`
const ReturnHome = styled.p`
    text-decoration: underline;
`
const LogoImg = styled.div`
  position: absolute;
  padding: 10px 0 0 5px;
  height: 65px;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding: 1rem 0 0 1rem;
  }
`

export default ({data}) => (
      console.log(data.sanityIdentity),
      <Background>
            <LinkHome to={"/"}>
                <LogoImg>
                    <Img
                        fixed={data.sanityIdentity.logo.asset.fixed} 
                    />
                </LogoImg>
                <Text>
                    <Paragraph>404</Paragraph>
                    <ReturnHome>Retourner à l'accueil</ReturnHome>
                </Text>
            </LinkHome>
      </Background>
  )

export const query = graphql`
  query {
    site {
      siteMetadata {
        clientToken
        siteUrl
        title
      }
    }
    sanityIdentity {
      mobile
      email
      logo {
        asset {
          fixed(width: 190) {
            ...GatsbySanityImageFixed
          }
        }
      }
    }
  }
`
